import { useFormContext } from "react-hook-form";

interface FormDataRoomConfigurationOption {
  adults: number;
  children: number;
}

interface FormDataRoomConfiguration {
  roomTypeId: string | null;
  adults: number;
  children: number;
  childAgeIndices: number[];
  options?: Record<string, FormDataRoomConfigurationOption>;
}

export interface FormDataRoomConfigurations {
  roomConfigurations: FormDataRoomConfiguration[];
}

export const useIndividualAssignmentFormContext = () =>
  useFormContext<FormDataRoomConfigurations>();

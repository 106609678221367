import { BookingStep } from "../../@types";
import {
  useBookingStore,
  useBookingStoreSetStep,
} from "../../hooks/useBookingStore";
import { useRoomTypes } from "../../http/roomApi";
import { HeaderPortal } from "../../layouts/Header";
import Button from "../../ui/Button";
import CenterWrapper from "../../ui/CenterWrapper";
import Mail from "../../ui/icon/mail.svg?react";
import Phone from "../../ui/icon/phone.svg?react";
import { site } from "../../utils/site";
import { removeAllWhitespace } from "../../utils/string";
import RoomDetail from "./RoomDetail";
import styles from "./StaticRoomDetail.module.css";

interface StaticRoomDetailProps {
  roomId: string;
}

const StaticRoomDetail = ({ roomId }: StaticRoomDetailProps) => {
  const language = useBookingStore((state) => state.language);
  const setStep = useBookingStoreSetStep();
  const { roomTypes } = useRoomTypes();
  const room = roomTypes?.find((x) => x.id === roomId);

  if (!room) {
    return null;
  }

  return (
    <div className={styles.staticRoomDetail}>
      <HeaderPortal>
        <header className={styles.header}>
          <CenterWrapper className={styles.inner}>
            <div className={styles.icons}>
              <a
                href={`tel:${removeAllWhitespace(site.phone_number)}`}
                className={styles.iconLink}
              >
                <Phone className={styles.icon} />
              </a>
              <a href={`mailto:${site.email}`} className={styles.iconLink}>
                <Mail className={styles.icon} />
              </a>
              <Button
                layout="link"
                buttonProps={{
                  className: styles.logoButton,
                  title: site.property_name,
                  onClick: () => {
                    history.pushState({}, "", `/${language}`);
                    setStep(BookingStep.Start);
                  },
                }}
              >
                <img
                  className={styles.logo}
                  alt={site.property_name}
                  title={site.property_name}
                  src={site.logo.url}
                  width={site.logo.width}
                  height={site.logo.height}
                />
              </Button>
            </div>
          </CenterWrapper>
        </header>
      </HeaderPortal>
      <RoomDetail room={room} shareView={true} />
    </div>
  );
};

export default StaticRoomDetail;

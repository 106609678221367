import useEmblaCarousel from "embla-carousel-react";
import { useState } from "react";
import { useBookingStore } from "../../hooks/useBookingStore";
import { useAutoOffers } from "../../http/offerApi";
import { translate } from "../../i18n";
import PageWithHeader from "../../pages/PageWithHeader";
import Button from "../../ui/Button";
import { Card } from "../../ui/Card";
import Headline from "../../ui/Headline";
import Hand from "../../ui/icon/hand.svg?react";
import { site } from "../../utils/site";
import styles from "./AutoOccupancy.module.css";
import AutoOffer from "./AutoOffer";
import IndividualAssignment from "./IndividualAssignment";

const AutoOccupancy = () => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);
  const { data: autoOffers } = useAutoOffers();
  const [emblaRef] = useEmblaCarousel({
    active: true,
    breakpoints: {
      "(min-width: 900px)": { active: false },
    },
  });
  const [customOfferModalOpen, setCustomOfferModalOpen] = useState(false);

  return (
    <PageWithHeader
      header={
        <Headline
          as="h1"
          size={3}
          title={i18n.autoOccupancy.ourSuggestion[site.guest_interaction]}
        />
      }
    >
      <div className={styles.autoOffers} ref={emblaRef}>
        <div className={styles.autoOffersSlider}>
          {autoOffers?.map((autoOffer, index) => (
            <AutoOffer
              key={index}
              index={index}
              autoOffer={autoOffer}
              className={styles.autoOffer}
            />
          ))}
        </div>
      </div>
      <IndividualAssignment
        onClose={() => setCustomOfferModalOpen(false)}
        open={customOfferModalOpen}
      />
      <Card
        className={styles.customOffer}
        contentClassName={styles.customOfferContent}
        footerClassName={styles.customOfferFooter}
        footer={
          <Button
            buttonProps={{
              title: i18n.autoOccupancy.assignGuestsIndividually,
              className: styles.customOfferButton,
              onClick: () => setCustomOfferModalOpen(true),
            }}
          >
            {i18n.autoOccupancy.assignGuestsIndividually}
          </Button>
        }
      >
        <Hand className={styles.customOfferIcon} />
        <div className={styles.customOfferText}>
          <Headline
            className={styles.customOfferTitle}
            size={3}
            title={i18n.autoOccupancy.noSuitableSuggestion}
          />
          <Headline
            className={styles.customOfferSubtitle}
            size={4}
            title={i18n.autoOccupancy.splitGuests[site.guest_interaction](
              site.room_term,
            )}
          />
        </div>
      </Card>
    </PageWithHeader>
  );
};

export default AutoOccupancy;

import { useState } from "react";
import { Prices as PricesType } from "../../@types";
import { useBookingStore } from "../../hooks/useBookingStore";
import { BoardTypes, Offer } from "../../http/offerApi";
import {
  RatePlan as RatePlanType,
  RatePlanType as RatePlanTypes,
  useRatePlans,
} from "../../http/ratePlanApi";
import { translate } from "../../i18n";
import Button from "../../ui/Button";
import { Card } from "../../ui/Card";
import Headline from "../../ui/Headline";
import Check from "../../ui/icon/check.svg?react";
import Special from "../../ui/icon/special.svg?react";
import Prices from "../../ui/Prices";
import RatePlanDetailModal from "./RatePlanDetailModal";
import styles from "./RatePlans.module.css";

interface RatePlansProps {
  offer: Offer;
}

const RatePlans = ({ offer }: RatePlansProps) => {
  const { data: ratePlans } = useRatePlans();
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);

  return (
    <>
      <Headline title={i18n.ratePlans.selectRate} as="h2" size={2} />
      <div className={styles.ratePlans}>
        {offer.rates.map((rate, index) => (
          <RatePlan
            key={index}
            prices={rate.prices}
            boardTypes={rate.board_types}
            ratePlan={ratePlans?.find(
              (ratePlan) => ratePlan.id === rate.rate_plan_id,
            )}
          />
        ))}
      </div>
    </>
  );
};

interface RatePlanProps {
  ratePlan: RatePlanType | undefined;
  prices: PricesType;
  boardTypes: BoardTypes[];
}

const RatePlan = ({ ratePlan, prices, boardTypes }: RatePlanProps) => {
  const [showDetailView, setShowDetailView] = useState(false);
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);

  if (!ratePlan) {
    return null;
  }

  return (
    <>
      <RatePlanDetailModal
        ratePlan={ratePlan}
        boardTypes={boardTypes}
        open={showDetailView}
        onClose={() => setShowDetailView(false)}
      />
      <Card
        footer={
          <Button
            buttonProps={{
              title: i18n.ratePlans.continue,
              onClick: () => setShowDetailView(true),
            }}
          >
            {i18n.ratePlans.continue}
          </Button>
        }
      >
        <div className={styles.titleContainer}>
          <Button
            layout="link"
            buttonProps={{
              title: ratePlan.title,
              onClick: () => setShowDetailView(true),
              className: styles.rateTitle,
            }}
          >
            {ratePlan.title}
          </Button>
          {ratePlan.type === RatePlanTypes.Special && (
            <Special className={styles.specialIcon} />
          )}
        </div>
        <Prices
          minPrice={prices.min}
          minPricePerNight={prices.min_per_night}
          discount={prices.discount}
          basePrice={prices.base}
        />
        {ratePlan.inclusives.length > 0 && (
          <ul className={styles.inclusives}>
            {ratePlan.inclusives.map((inclusive) => (
              <li key={inclusive.id} className={styles.inclusiveItem}>
                <Check className={styles.inclusiveItemIcon} />
                {inclusive.title}
              </li>
            ))}
          </ul>
        )}
        <div className={styles.detailLinkWrapper}>
          <Button
            layout="link"
            buttonProps={{
              className: styles.detailLink,
              title: i18n.ratePlans.showMoreDetails,
              onClick: () => setShowDetailView(true),
            }}
          >
            {i18n.ratePlans.showMoreDetails}
          </Button>
        </div>
      </Card>
    </>
  );
};

export default RatePlans;

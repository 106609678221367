import { useEffect } from "react";
import { BookingStep, Language } from "./@types";
import AutoOccupancy from "./domain/autoOccupancy/AutoOccupancy";
import Calendar from "./domain/calendar/Calendar";
import Checkout from "./domain/checkout/Checkout";
import Extra from "./domain/extra/Extra";
import Init from "./domain/init/Init";
import Occupancy from "./domain/occupancy/Occupancy";
import RoomConfigurations from "./domain/room/RoomConfigurations";
import Rooms from "./domain/room/Rooms";
import Start from "./domain/start/Start";
import {
  useBookingStore,
  useBookingStoreSetStep,
} from "./hooks/useBookingStore";

const Router = () => {
  const currentStep = useBookingStore((state) => state.step);
  const setStep = useBookingStoreSetStep();
  const currentLanguage = useBookingStore((state) => state.language);
  const setLanguage = useBookingStore((state) => state.setLanguage);

  useEffect(() => {
    const handlePopstate = (e: PopStateEvent) => {
      const historyStep: BookingStep =
        e.state?.bookingStore?.step ?? currentStep;
      const historyLanguage: Language =
        e.state?.bookingStore?.language ?? currentLanguage;

      if (historyStep !== currentStep) {
        setStep(historyStep, false);
      }
      if (historyLanguage !== currentLanguage) {
        setLanguage(historyLanguage, false);
      }
    };
    window.addEventListener("popstate", handlePopstate);
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [currentLanguage, currentStep, setLanguage, setStep]);

  switch (currentStep) {
    case BookingStep.Start:
      return <Start />;
    case BookingStep.Occupancy:
      return <Occupancy />;
    case BookingStep.Calendar:
      return <Calendar />;
    case BookingStep.Checkout:
      return <Checkout />;
    case BookingStep.AutoOccupancy:
      return <AutoOccupancy />;
    case BookingStep.RoomConfigurations:
      return <RoomConfigurations />;
    case BookingStep.Rooms:
      return <Rooms />;
    case BookingStep.Extra:
      return <Extra />;
    default: {
      return <Init />;
    }
  }
};

export default Router;

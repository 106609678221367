import { ReactNode } from "react";
import { Room } from "../../http/roomApi";
import Guests from "../../ui/icon/guests.svg?react";
import Size from "../../ui/icon/qm.svg?react";
import { Label } from "../../ui/Label";
import styles from "./RoomInfos.module.css";

interface RoomInfosProps {
  room: Room;
  prices?: ReactNode;
}

const RoomInfos = ({ room, prices }: RoomInfosProps) => {
  return (
    <>
      <Label className={styles.roomInfos}>
        <div className={styles.roomInfo}>
          <Guests className={styles.roomDetailIcon} />
          {`${room.occupancy.min}`}
          {room.occupancy.max > room.occupancy.min && `–${room.occupancy.max}`}
        </div>
        {room.size && (
          <div className={styles.roomInfo}>
            <Size className={styles.roomDetailIcon} />
            {room.size && <div>{room.size}&nbsp;m²</div>}
          </div>
        )}
      </Label>
      {prices && prices}
    </>
  );
};

export default RoomInfos;

import useSWR from "swr";
import { Photo } from "../@types";
import { useBookingStore } from "../hooks/useBookingStore";
import { fetcher } from "./utils";

export interface RoomTypes {
  room_types: Room[];
  amenities: Amenity[];
  amenity_groups: AmenityGroup[];
}

export interface Room {
  id: string;
  name: string;
  description: string;
  occupancy: {
    min: number;
    max: number;
  };
  size?: number;
  amenity_ids: string[];
  photos: Photo[];
}

export interface Amenity {
  id: string;
  name: string;
  is_favored: boolean;
  group_id: string;
}

export interface AmenityGroup {
  id: string;
  name: string;
}

// TODO: replace with live url: `/properties/${propertyId}/room_types`
const roomTypesURL = "/room-types-de.json";

const useRoomTypesInternal = () => {
  const language = useBookingStore((state) => state.language);

  return useSWR({ url: roomTypesURL, language }, (opts) =>
    fetcher<RoomTypes>(opts),
  );
};

export const useRoomTypes = () => {
  const { data, ...rest } = useRoomTypesInternal();

  if (data) {
    return {
      ...rest,
      roomTypes: data.room_types,
    };
  }

  return { roomTypes: null, ...rest };
};

export const useAmenities = () => {
  const { data, ...rest } = useRoomTypesInternal();

  if (data) {
    return {
      ...rest,
      amenities: data.amenities,
    };
  }

  return { amenities: null, ...rest };
};

export const useAmenityGroups = () => {
  const { data, ...rest } = useRoomTypesInternal();

  if (data) {
    return {
      ...rest,
      amenityGroups: data.amenity_groups,
    };
  }

  return { amenityGroups: null, ...rest };
};

import { BookingStep } from "../../@types";
import {
  useBookingStore,
  useBookingStoreSetStep,
} from "../../hooks/useBookingStore";
import { useRoomTypes } from "../../http/roomApi";
import { translate } from "../../i18n";
import Form from "../../ui/Form";
import Children from "../../ui/icon/children.svg?react";
import Guests from "../../ui/icon/guests.svg?react";
import { Label } from "../../ui/Label";
import MobileModal, { MobileModalHeader } from "../../ui/MobileModal";
import { site } from "../../utils/site";
import styles from "./IndividualAssignment.module.css";
import IndividualAssignmentForm from "./IndividualAssignmentForm";
import { FormDataRoomConfigurations } from "./utils";

interface IndividualAssignmentProps {
  open: boolean;
  onClose: () => void;
}

const IndividualAssignment = ({ open, onClose }: IndividualAssignmentProps) => {
  const adults = useBookingStore((state) => state.autoOccupancy?.adults) ?? 0;
  const children =
    useBookingStore((state) => state.autoOccupancy?.children) ?? [];
  const language = useBookingStore((state) => state.language);
  const setStep = useBookingStoreSetStep();
  const setRoomConfigurations = useBookingStore(
    (state) => state.setRoomConfigurations,
  );
  const i18n = translate(language);
  const { roomTypes } = useRoomTypes();

  if (!roomTypes) {
    return null;
  }

  return (
    <MobileModal size="small" align="center" isOpen={open} onClose={onClose}>
      <MobileModalHeader
        hideOnDesktop={false}
        onClose={onClose}
        title={i18n.autoOccupancy.customOffer[site.guest_interaction](
          site.room_term,
        )}
        titleFooter={
          <div className={styles.headerOccupancy}>
            <Label className={styles.headerOccupancyLabel}>
              {adults > 0 && (
                <div className={styles.headerOccupancyGroup}>
                  <Guests className={styles.headerOccupancyIcon} />
                  {i18n.autoOccupancy.adultsCount(adults)}
                </div>
              )}
              {children.length > 0 && (
                <div className={styles.headerOccupancyGroup}>
                  <Children className={styles.headerOccupancyIcon} />
                  {i18n.autoOccupancy.childrenCount(children.length)}
                </div>
              )}
            </Label>
          </div>
        }
      ></MobileModalHeader>
      <Form<FormDataRoomConfigurations>
        defaultValues={{
          roomConfigurations: [
            {
              roomTypeId: null,
              adults: 0,
              children: 0,
              childAgeIndices: [],
            },
          ],
        }}
        className={styles.form}
        onSubmit={(data) => {
          setRoomConfigurations(
            data.roomConfigurations.map((roomConfiguration, index) => ({
              roomIndex: index,
              roomTypeId: roomConfiguration.roomTypeId,
              adults: roomConfiguration.adults,
              children: roomConfiguration.childAgeIndices.map(
                (index) => children[index] ?? 0,
              ),
            })),
          );
          setStep(BookingStep.RoomConfigurations);
        }}
      >
        <IndividualAssignmentForm />
      </Form>
    </MobileModal>
  );
};

export default IndividualAssignment;

import { addMonths, endOfMonth, startOfMonth } from "date-fns";
import { useMemo } from "react";
import useSWR from "swr";
import useSWRInfinite from "swr/infinite";
import { Price } from "../@types";
import {
  setOccupancySearchParams,
  useBookingStore,
} from "../hooks/useBookingStore";
import { formatDateNullable, parseDate } from "../utils/date";
import { fetcher } from "./utils";

const now = new Date();
const minDate = startOfMonth(now);
const maxDate = endOfMonth(addMonths(now, 24));

export interface ArrivalAvailabilityDay {
  date: string;
  price: Price;
}

export interface ArrivalAvailability {
  arrival_days: ArrivalAvailabilityDay[];
}

export interface DepartureAvailability {
  min_stay: number;
  max_stay: number;
  departure_days: string[];
}

// TODO: replace with live url: `/properties/${propertyId}/availabilities/arrivals`
const arrivalAvailabilitiesURL = "/availabilities-arrival.json";

// TODO: replace with live url: `/properties/${propertyId}/availabilities/departure`
const departureAvailabilitiesURL = "/availabilities-departures.json";

export const fetchArrivalAvailabilities = async (searchParamString: string) => {
  return fetcher<ArrivalAvailability>({
    url: `${arrivalAvailabilitiesURL}?${searchParamString}`,
  });
};

interface UseArrivalAvailabilitiesArguments {
  initialStart?: Date | null;
  initialSize?: number;
}

export const useArrivalAvailabilities = ({
  initialStart,
  initialSize = 3,
}: UseArrivalAvailabilitiesArguments = {}) => {
  const autoOccupancy = useBookingStore((state) => state.autoOccupancy);
  const occupancies = useBookingStore((state) => state.occupancies);

  if (!initialStart) {
    initialStart = minDate;
  }
  const { data, error, isLoading, isValidating, mutate, size, setSize } =
    useSWRInfinite(
      (pageIndex: number) => {
        const start = addMonths(initialStart, pageIndex);
        if (start < minDate || start > maxDate) {
          return null;
        }
        const end = endOfMonth(start);

        const formattedStartDate = formatDateNullable(start);
        const formattedEndDate = formatDateNullable(end);

        if (!formattedStartDate || !formattedEndDate) {
          return null;
        }

        const searchParams = new URLSearchParams();
        searchParams.set("start", formattedStartDate);
        searchParams.set("end", formattedEndDate);

        setOccupancySearchParams(searchParams, {
          autoOccupancy,
          occupancies,
        });

        return searchParams.toString();
      },
      (searchParamString) => fetchArrivalAvailabilities(searchParamString),
      {
        initialSize,
        revalidateIfStale: true,
        revalidateFirstPage: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        parallel: true,
      },
    );

  const flattenedArrivalAvailability = useMemo(() => {
    return data?.reduce(
      (
        flattenedArrivalAvailability: ArrivalAvailability | undefined,
        arrivalAvailability,
      ) => {
        if (!flattenedArrivalAvailability) {
          return arrivalAvailability;
        }

        flattenedArrivalAvailability.arrival_days.concat(
          arrivalAvailability.arrival_days,
        );

        return flattenedArrivalAvailability;
      },
    );
  }, [data]);

  const arrivalAvailabilityDayMap = useMemo(() => {
    const map = new Map<number, ArrivalAvailabilityDay>();
    flattenedArrivalAvailability?.arrival_days.forEach(
      (element: ArrivalAvailabilityDay) => {
        const date = parseDate(element.date);
        map.set(date.getTime(), element);
      },
    );

    return map;
  }, [flattenedArrivalAvailability]);

  return {
    data: flattenedArrivalAvailability,
    dataMap: arrivalAvailabilityDayMap,
    error,
    isLoading,
    isValidating,
    mutate,
    size,
    setSize,
  };
};

export const fetchDepartureAvailabilities = async (
  searchParamString: string,
) => {
  return fetcher<DepartureAvailability>({
    url: `${departureAvailabilitiesURL}?${searchParamString}`,
  });
};

export const useDepartureAvailabilities = (arrival: Date | null) => {
  const autoOccupancy = useBookingStore((state) => state.autoOccupancy);
  const occupancies = useBookingStore((state) => state.occupancies);

  return useSWR(
    () => {
      const formattedDate = formatDateNullable(arrival);
      if (!formattedDate) {
        return null;
      }

      const searchParams = new URLSearchParams();
      searchParams.set("arrival", formattedDate);

      setOccupancySearchParams(searchParams, {
        autoOccupancy,
        occupancies,
      });

      return searchParams.toString();
    },
    (searchParamString) => fetchDepartureAvailabilities(searchParamString),
    {
      revalidateIfStale: true,
      revalidateFirstPage: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );
};

import { differenceInDays, isAfter, isSameDay, startOfToday } from "date-fns";
import { create } from "zustand";
import { PersistStorage, StorageValue, persist } from "zustand/middleware";
import {
  AutoOccupancy,
  BookingStep,
  Language,
  Occupancy,
  RoomConfiguration,
  Site,
} from "../@types";
import {
  maxAdultsAutoOccupancy,
  maxChildrenAge,
  maxChildrenAutoOccupancy,
  staticRoomDetailPattern,
} from "../utils/constants";
import { formatDateNullable, parseDateNullable } from "../utils/date";
import { getLanguageByCode } from "../utils/language";
import { isUUID } from "../utils/string";
import { useButtonGroupStore } from "./useButtonGroupStore";

interface BookingStoreState {
  hydratedValuesValidated: boolean;
  hydratedWithDefaultOccupancy: boolean;
  userInteracted: boolean;
  occupancies: Occupancy[];
  autoOccupancy: AutoOccupancy | null;
  arrival: Date | null;
  departure: Date | null;
  roomTypeId: string | null;
  ratePlanId: string | null;
  step: BookingStep;
  bookingAmount: number;
  language: Language;
  roomConfigurations: RoomConfiguration[];
  currentRoomConfigurationIndex: number | null;
}

interface BookingStoreActions {
  setHydratedValuesValidated: (hydratedValuesValidated: boolean) => void;
  setOccupancies: (occupancies: Occupancy[]) => void;
  setAutoOccupancy: (autoOccupancy: AutoOccupancy | null) => void;
  setArrivalAndDeparture: ({
    arrival,
    departure,
  }: {
    arrival: Date | null;
    departure: Date | null;
  }) => void;
  setBookingAmount: (bookingAmount: number) => void;
  setLanguage: (language: Language | null, updateHistory?: boolean) => void;
  setStep: (step: BookingStep, updateHistory?: boolean) => void;
  setCurrentRoomConfigurationIndex: (index: number | null) => void;
  updateRoomConfiguration: (
    configuration: Partial<RoomConfiguration>,
    index?: number | null,
  ) => void;
  setRoomConfigurations: (roomConfigurations: RoomConfiguration[]) => void;
}

type BookingStore = BookingStoreState & BookingStoreActions;

type PersistedBookingStoreState = Omit<
  BookingStoreState,
  "hydratedValuesValidated" | "step" | "bookingAmount"
>;

interface OccupancySummary {
  adults: number;
  children: number;
  childrenAges: number[];
  rooms: number;
}

const defaultPersistedState: PersistedBookingStoreState = {
  hydratedWithDefaultOccupancy: true,
  userInteracted: false,
  occupancies: [
    {
      adults: 2,
      children: [],
      roomTypeId: null,
    },
  ],
  autoOccupancy: null,
  roomTypeId: null,
  ratePlanId: null,
  arrival: null,
  departure: null,
  language: Language.English,
  roomConfigurations: [],
  currentRoomConfigurationIndex: null,
};

const defaultState: BookingStoreState = {
  ...defaultPersistedState,
  hydratedValuesValidated: false,
  step: BookingStep.Init,
  bookingAmount: 0,
};

const adultsSearchParamKey = (occupancyIndex: number): string =>
  `occupancies[${occupancyIndex}][adults]`;

const childrenSearchParamKey = (
  occupancyIndex: number,
  childrenIndex: number,
): string => `occupancies[${occupancyIndex}][children][${childrenIndex}]`;

const roomTypeIdSearchParamKey = (occupancyIndex: number): string =>
  `occupancies[${occupancyIndex}][room_type_id]`;

const validateOccupancyFields = (
  adults: number | null,
  children: number[],
  roomTypeId?: string | null,
  occupancyIndex?: number,
): boolean => {
  // validate occupancy
  // error prefix
  const errorPrefix =
    occupancyIndex === undefined
      ? "invalid auto_occupancy"
      : `invalid occupancy for index ${occupancyIndex}`;

  // not set
  if (adults === null) {
    if (children.length) {
      console.warn(`${errorPrefix} - children provided but no adults`);
    }
    return false;
  }

  // invalid value
  if (adults <= 0 || isNaN(adults)) {
    console.warn(`${errorPrefix} - adults has to be a number greater than 0`);
    return false;
  }

  // invalid value
  if (adults > 99) {
    console.warn(`${errorPrefix} - adults has to be a number less than 100`);
    return false;
  }

  // invalid value
  if (children.length > 99) {
    console.warn(`${errorPrefix} - children count has to be less than 100`);
    return false;
  }

  // invalid child ages
  if (children.some((x) => x >= 18 || x < 0)) {
    console.warn(
      `${errorPrefix} - child age has to be greater or equal than 0 and less than 18`,
    );
    return false;
  }

  if (roomTypeId && !isUUID(roomTypeId)) {
    console.warn(
      `${errorPrefix} - room_type_id, if provided, has to be a uuid - value provided "${roomTypeId}"`,
    );
    return false;
  }

  return true;
};

const extractLanguage = (): Language => {
  const languageCode = new Intl.Locale(document.documentElement.lang).language;
  return getLanguageByCode(languageCode) ?? defaultPersistedState.language;
};

const extractOccupancyParamLimits = (
  searchParams: URLSearchParams,
  defaultMaxOccupancyIndex: number,
): {
  maxOccupancyIndex: number;
  maxOccupancyChildrenIndex: number;
  maxAutoOccupancyIndex: number;
  maxAutoOccupancyChildrenIndex: number;
} => {
  const limits = {
    maxOccupancyIndex: defaultMaxOccupancyIndex,
    maxOccupancyChildrenIndex: -1,
    maxAutoOccupancyIndex: -1,
    maxAutoOccupancyChildrenIndex: -1,
  };

  const occupanciesRegex = /occupancies\[(\d)\](?:\[children\]\[(\d)\])?/;
  const autoOccupancyRegex = /auto_occupancy\[(?:children\]\[(\d)\])?/;
  searchParams.forEach((_value, key) => {
    const autoOccupancyMatch = autoOccupancyRegex.exec(key);
    if (autoOccupancyMatch) {
      limits.maxAutoOccupancyIndex = 0;
      const childrenIndex = parseInt(autoOccupancyMatch[1] ?? "", 10);
      if (childrenIndex > limits.maxAutoOccupancyChildrenIndex) {
        limits.maxAutoOccupancyChildrenIndex = childrenIndex;
      }
      return;
    }

    const occupancyMatch = occupanciesRegex.exec(key);
    if (!occupancyMatch) {
      return;
    }

    const index = parseInt(occupancyMatch[1] ?? "", 10);
    if (index > limits.maxOccupancyIndex) {
      limits.maxOccupancyIndex = index;
    }

    const childrenIndex = parseInt(occupancyMatch[2] ?? "", 10);
    if (childrenIndex > limits.maxOccupancyChildrenIndex) {
      limits.maxOccupancyChildrenIndex = childrenIndex;
    }
  });

  return limits;
};

interface ExtractAutoOccupancyArguments {
  maxIndex: number;
  maxChildrenIndex: number;
}
const extractAutoOccupancy = (
  searchParams: URLSearchParams,
  { maxIndex, maxChildrenIndex }: ExtractAutoOccupancyArguments,
): AutoOccupancy | undefined => {
  if (maxIndex < 0) {
    return;
  }

  const adultsString = searchParams.get("auto_occupancy[adults]");
  const adults = adultsString ? parseInt(adultsString, 10) : null;

  const children: number[] = [];

  for (let j = 0; j <= maxChildrenIndex; j++) {
    const childrenString = searchParams.get(`auto_occupancy[children][${j}]`);
    const childAge = parseInt(childrenString ?? "", 10);
    if (!isNaN(childAge)) {
      children.push(childAge);
    }
  }

  const isAutoOccupancyValid = validateOccupancyFields(adults, children);
  if (!isAutoOccupancyValid) {
    return;
  }

  return {
    adults: adults ?? 0, // should not be possible, because of validateOccupancyFields
    children,
  };
};

interface ExtractOccupanciesArguments {
  maxIndex: number;
  maxChildrenIndex: number;
}
const extractOccupancies = (
  searchParams: URLSearchParams,
  { maxIndex, maxChildrenIndex }: ExtractOccupanciesArguments,
): Occupancy[] => {
  const occupancies: Occupancy[] = [];
  for (let i = 0; i <= maxIndex; i++) {
    const adultsString = searchParams.get(adultsSearchParamKey(i));
    const adults = adultsString ? parseInt(adultsString, 10) : null;

    const children = [];

    for (let j = 0; j <= maxChildrenIndex; j++) {
      const childrenString = searchParams.get(childrenSearchParamKey(i, j));
      const childAge = parseInt(childrenString ?? "", 10);

      if (!isNaN(childAge)) {
        children.push(childAge);
      }
    }

    const roomTypeId = searchParams.get(roomTypeIdSearchParamKey(i));

    if (!validateOccupancyFields(adults, children, roomTypeId, i)) {
      continue;
    }

    occupancies.push({
      adults: adults ?? 0, // should not be possible, because of validateOccupancyFields
      children,
      roomTypeId,
    });
  }

  return occupancies;
};

const validateStayFields = (
  arrival: Date | null,
  departure: Date | null,
): boolean => {
  // validate dates
  // - arrival != departure
  if (arrival && departure && isSameDay(arrival, departure)) {
    console.warn(
      "invalid date - arrival and departure have to be different dates",
    );
    return false;
  }

  // - arrival < departure
  if (arrival && departure && isAfter(arrival, departure)) {
    console.warn("invalid date - arrival has to be before departure");
    return false;
  }

  // - arrival >= today
  if (arrival && arrival < startOfToday()) {
    console.warn("invalid date - arrival can't be before today");
    return false;
  }

  return true;
};

const extractDates = (
  searchParams: URLSearchParams,
): Pick<PersistedBookingStoreState, "arrival" | "departure"> | undefined => {
  const dateFields: ("arrival" | "departure")[] = ["arrival", "departure"];
  const dates: Pick<PersistedBookingStoreState, "arrival" | "departure"> = {
    arrival: defaultState.arrival,
    departure: defaultState.departure,
  };

  dateFields.forEach((fieldName) => {
    const param = searchParams.get(fieldName);
    if (param) {
      const date = parseDateNullable(param);
      if (date === null) {
        console.warn(
          `invalid date for ${fieldName} - format required "yyyy-MM-dd" - value provided "${param}"`,
        );
      } else {
        dates[fieldName] = date;
      }
    }
  });

  if (!validateStayFields(dates.arrival, dates.departure)) {
    return;
  }

  return dates;
};

const extractRoomTypeId = (searchParams: URLSearchParams): string | null => {
  const roomTypeId = searchParams.get("room_type_id");
  if (roomTypeId) {
    if (isUUID(roomTypeId)) {
      return roomTypeId;
    }

    console.warn(
      `invalid room type id - has to be a uuid - value provided "${roomTypeId}"`,
    );
  }

  return null;
};

const extractRatePlanId = (searchParams: URLSearchParams): string | null => {
  const ratePlanId = searchParams.get("rate_plan_id");
  if (ratePlanId) {
    if (isUUID(ratePlanId)) {
      return ratePlanId;
    }

    console.warn(
      `invalid rate plan id - has to be a uuid - value provided "${ratePlanId}"`,
    );
  }

  return null;
};

const generateRoomConfigurations = (
  autoOccupancy: AutoOccupancy | null,
  occupancies: Occupancy[],
): RoomConfiguration[] => {
  return autoOccupancy
    ? []
    : occupancies.map((occupancy, index) => ({
        roomIndex: index,
        adults: occupancy.adults,
        children: occupancy.children.slice(),
        roomTypeId: occupancy.roomTypeId,
      }));
};

const generateRoomConfigurationData = (
  autoOccupancy: AutoOccupancy | null,
  occupancies: Occupancy[],
): Pick<
  BookingStoreState,
  "roomConfigurations" | "currentRoomConfigurationIndex"
> => {
  const roomConfigurations = generateRoomConfigurations(
    autoOccupancy,
    occupancies,
  );

  return {
    roomConfigurations,
    currentRoomConfigurationIndex: roomConfigurations.length ? 0 : null,
  };
};

const dateChanged = (oldDate: Date | null, newDate: Date | null): boolean => {
  return (
    oldDate !== newDate &&
    (oldDate === null || newDate === null || !isSameDay(oldDate, newDate))
  );
};

const occupancyChanged = (
  oldOccupancy: Occupancy,
  newOccupancy: Occupancy,
): boolean => {
  const oldChildrenString = oldOccupancy.children.join("_");
  const newChildrenString = newOccupancy.children.join("_");
  return (
    oldOccupancy.adults !== newOccupancy.adults ||
    oldOccupancy.roomTypeId !== newOccupancy.roomTypeId ||
    oldChildrenString !== newChildrenString
  );
};

const occupanciesChanged = (
  oldOccupancies: Occupancy[],
  newOccupancies: Occupancy[],
): boolean => {
  if (oldOccupancies.length !== newOccupancies.length) {
    return true;
  }

  return oldOccupancies.some((oldOccupancy, index) => {
    const newOccupancy = newOccupancies[index];
    return !newOccupancy || occupancyChanged(oldOccupancy, newOccupancy);
  });
};

interface SetOccupancySearchParamsArguments {
  autoOccupancy: AutoOccupancy | null;
  occupancies: Occupancy[];
}
export const setOccupancySearchParams = (
  searchParams: URLSearchParams,
  { autoOccupancy, occupancies }: SetOccupancySearchParamsArguments,
) => {
  if (autoOccupancy) {
    const occupancy = autoOccupancy;
    if (occupancy.adults) {
      searchParams.set("auto_occupancy[adults]", occupancy.adults.toString());
      occupancy.children.forEach((childAge, j) => {
        searchParams.set(`auto_occupancy[children][${j}]`, childAge.toString());
      });
    }
  } else {
    occupancies.forEach((occupancy, i) => {
      if (!occupancy.adults) {
        return;
      }

      searchParams.set(adultsSearchParamKey(i), occupancy.adults.toString());
      occupancy.children.forEach((childAge, j) => {
        searchParams.set(childrenSearchParamKey(i, j), childAge.toString());
      });
      if (occupancy.roomTypeId) {
        searchParams.set(
          roomTypeIdSearchParamKey(i),
          occupancy.roomTypeId.toString(),
        );
      }
    });
  }
};

const pushHistoryState = (
  state: BookingStore,
  newState: Partial<Pick<BookingStore, "step" | "language">>,
) => {
  history.pushState(
    {
      ...history.state,
      bookingStore: {
        ...history.state?.bookingStore,
        language: newState.language ?? state.language,
        step: newState.step ?? state.step,
      },
    },
    "",
  );
};

const urlSearchParamStorage: PersistStorage<PersistedBookingStoreState> = {
  getItem: (): StorageValue<PersistedBookingStoreState> => {
    const searchParams = new URLSearchParams(location.search.slice(1));
    const state = {
      ...defaultPersistedState,
    };

    state.language = extractLanguage();

    const {
      maxOccupancyIndex,
      maxOccupancyChildrenIndex,
      maxAutoOccupancyIndex,
      maxAutoOccupancyChildrenIndex,
    } = extractOccupancyParamLimits(searchParams, state.occupancies.length - 1);

    const autoOccupancy = extractAutoOccupancy(searchParams, {
      maxIndex: maxAutoOccupancyIndex,
      maxChildrenIndex: maxAutoOccupancyChildrenIndex,
    });

    if (autoOccupancy) {
      state.hydratedWithDefaultOccupancy = false;
      state.autoOccupancy = autoOccupancy;
    } else {
      const occupancies = extractOccupancies(searchParams, {
        maxIndex: maxOccupancyIndex,
        maxChildrenIndex: maxOccupancyChildrenIndex,
      });

      if (occupancies.length) {
        state.hydratedWithDefaultOccupancy = false;
        state.occupancies = occupancies;
      }
    }

    const dates = extractDates(searchParams);
    if (dates) {
      state.arrival = dates.arrival;
      state.departure = dates.departure;
    }

    const { roomConfigurations, currentRoomConfigurationIndex } =
      generateRoomConfigurationData(state.autoOccupancy, state.occupancies);
    state.roomConfigurations = roomConfigurations;
    state.currentRoomConfigurationIndex = currentRoomConfigurationIndex;

    state.roomTypeId = extractRoomTypeId(searchParams);
    state.ratePlanId = extractRatePlanId(searchParams);

    return {
      state,
    };
  },
  setItem: (_key, value): void => {
    const state = value.state;
    const searchString = location.search.slice(1);
    const searchParams = new URLSearchParams(searchString);

    // remove current occupancies params
    const keys = [...searchParams.keys()];
    keys.forEach((key) => {
      if (key.startsWith("occupancies[")) {
        searchParams.delete(key);
        return;
      }
      if (key.startsWith("auto_occupancy[")) {
        searchParams.delete(key);
        return;
      }
    });

    if (!state.hydratedWithDefaultOccupancy || state.userInteracted) {
      setOccupancySearchParams(searchParams, {
        autoOccupancy: state.autoOccupancy,
        occupancies: state.occupancies,
      });
    }

    const dateFields: ("arrival" | "departure")[] = ["arrival", "departure"];
    dateFields.forEach((fieldName) => {
      const value = formatDateNullable(state[fieldName]);
      if (value) {
        searchParams.set(fieldName, value);
      } else {
        searchParams.delete(fieldName);
      }
    });

    if (state.roomTypeId) {
      searchParams.set("room_type_id", state.roomTypeId);
    } else {
      searchParams.delete("room_type_id");
    }

    if (state.ratePlanId) {
      searchParams.set("rate_plan_id", state.ratePlanId);
    } else {
      searchParams.delete("rate_plan_id");
    }

    searchParams.sort();

    const isStaticRoomDetailView = staticRoomDetailPattern.test(
      location.pathname,
    );

    const finalSearchParams = isStaticRoomDetailView
      ? new URLSearchParams(searchString)
      : searchParams;

    const documentLanguage = getLanguageByCode(document.documentElement.lang);
    if (documentLanguage !== state.language) {
      document.documentElement.lang = state.language;
    }

    const currentURL = location.pathname + location.search;
    const segments = location.pathname.substring(1).split("/");
    segments[0] = state.language;
    const newURL = `/${segments.join("/")}${
      finalSearchParams.size ? `?${finalSearchParams.toString()}` : ""
    }`;

    if (currentURL !== newURL) {
      history.replaceState(history.state, "", newURL);
    }
  },
  removeItem: (): void => {
    const searchParams = new URLSearchParams(location.search.slice(1));
    searchParams.delete("arrival");
    searchParams.delete("departure");
    searchParams.delete("room_type_id");
    searchParams.delete("rate_plan_id");

    searchParams.forEach((_value, key) => {
      if (key.startsWith("occupancies[")) {
        searchParams.delete(key);
        return;
      }
      if (key.startsWith("auto_occupancy[")) {
        searchParams.delete(key);
        return;
      }
    });

    const currentURL = location.pathname + location.search;
    const newURL =
      location.pathname + searchParams.size
        ? `?${searchParams.toString()}`
        : "";
    if (currentURL !== newURL) {
      history.replaceState(history.state, "", newURL);
    }
  },
};

export const useBookingStore = create<BookingStore>()(
  persist(
    (set) => ({
      ...defaultState,
      setHydratedValuesValidated: (hydratedValuesValidated: boolean) =>
        set(() => ({ hydratedValuesValidated })),
      setOccupancies: (occupancies: Occupancy[]) =>
        set((state) => {
          const changed = occupanciesChanged(state.occupancies, occupancies);
          if (!changed && state.userInteracted) {
            return {};
          }

          const { roomConfigurations, currentRoomConfigurationIndex } =
            generateRoomConfigurationData(null, occupancies);

          return {
            userInteracted: true,
            occupancies,
            roomConfigurations,
            currentRoomConfigurationIndex,
          };
        }),
      setAutoOccupancy: (autoOccupancy: AutoOccupancy | null) =>
        set(() => {
          return {
            userInteracted: true,
            autoOccupancy,
            roomConfigurations: [],
            currentRoomConfigurationIndex: null,
          };
        }),
      setLanguage: (language: Language | null, updateHistory = true) =>
        set((state) => {
          if (!language) {
            return {};
          }

          if (updateHistory && state.language !== language) {
            pushHistoryState(state, {
              language,
            });
          }

          return { language };
        }),
      setBookingAmount: (bookingAmount: number) =>
        set(() => ({ bookingAmount })),
      setArrivalAndDeparture: ({ arrival = null, departure = null }) =>
        set((state) => {
          const arrivalChanged = dateChanged(state.arrival, arrival);
          const departureChanged = dateChanged(state.departure, departure);
          if (!arrivalChanged && !departureChanged && state.userInteracted) {
            return {};
          }

          const { roomConfigurations, currentRoomConfigurationIndex } =
            generateRoomConfigurationData(
              state.autoOccupancy,
              state.occupancies,
            );

          return {
            userInteracted: true,
            arrival,
            departure,
            roomConfigurations,
            currentRoomConfigurationIndex,
          };
        }),
      setStep: (step: BookingStep, updateHistory = true) =>
        set((state) => {
          if (updateHistory && state.step !== step) {
            pushHistoryState(state, {
              step,
            });
          }
          return { step };
        }),
      setCurrentRoomConfigurationIndex: (index: number | null) =>
        set(() => ({ currentRoomConfigurationIndex: index })),
      updateRoomConfiguration: (
        configuration: Partial<RoomConfiguration>,
        index?: number | null,
      ) =>
        set((state) => {
          index ??= state.currentRoomConfigurationIndex;
          if (index === null || state.roomConfigurations.length <= index) {
            return {};
          }

          const roomConfigurations = [...state.roomConfigurations];
          roomConfigurations[index] = {
            ...roomConfigurations[index],
            ...configuration,
            roomIndex: index,
          };

          return {
            roomConfigurations,
          };
        }),
      setRoomConfigurations: (roomConfigurations: RoomConfiguration[]) =>
        set(() => ({ roomConfigurations })),
    }),
    {
      name: "booking-storage",
      storage: urlSearchParamStorage,
      partialize: (state) => ({
        occupancies: state.occupancies,
        autoOccupancy: state.autoOccupancy,
        arrival: state.arrival,
        departure: state.departure,
        roomTypeId: state.roomTypeId,
        ratePlanId: state.ratePlanId,
        language: state.language,
        hydratedWithDefaultOccupancy: state.hydratedWithDefaultOccupancy,
        userInteracted: state.userInteracted,
        roomConfigurations: state.roomConfigurations,
        currentRoomConfigurationIndex: state.currentRoomConfigurationIndex,
      }),
    },
  ),
);

export const useBookingStoreSetStep = () => {
  const setStep = useBookingStore((state) => state.setStep);
  const arrival = useBookingStore((state) => state.arrival);
  const departure = useBookingStore((state) => state.departure);
  const setButtonGroupStoreArrival = useButtonGroupStore(
    (state) => state.setArrival,
  );
  const setButtonGroupStoreDeparture = useButtonGroupStore(
    (state) => state.setDeparture,
  );
  return (step: BookingStep, updateHistory?: boolean) => {
    setButtonGroupStoreArrival(arrival);
    setButtonGroupStoreDeparture(departure);
    setStep(step, updateHistory);
  };
};

export const selectOccupancySummary = (
  state: BookingStore,
): OccupancySummary => {
  if (state.autoOccupancy) {
    return {
      adults: state.autoOccupancy.adults,
      children: state.autoOccupancy.children.length,
      childrenAges: state.autoOccupancy.children,
      rooms: 0,
    };
  }

  return state.occupancies.reduce(
    (summary: OccupancySummary, occupancy) => {
      summary.rooms++;
      summary.adults += occupancy.adults;
      summary.children += occupancy.children.length;
      summary.childrenAges = summary.childrenAges.concat(occupancy.children);
      return summary;
    },
    {
      adults: 0,
      children: 0,
      childrenAges: [],
      rooms: 0,
    },
  );
};

export const selectNights = (state: BookingStore): number | null =>
  selectNightsFromArrivalAndDeparture(state.arrival, state.departure);

export const selectNightsFromArrivalAndDeparture = (
  arrival: Date | null,
  departure: Date | null,
): number | null => {
  if (!arrival || !departure) {
    return null;
  }

  return differenceInDays(departure, arrival);
};

export const selectCurrentRoomConfiguration = (
  state: BookingStore,
): RoomConfiguration | null => {
  const { roomConfigurations, currentRoomConfigurationIndex } = state;

  return roomConfigurations[currentRoomConfigurationIndex ?? -1] ?? null;
};

export const isRoomConfigurationValid = (
  roomConfiguration: RoomConfiguration,
): boolean => {
  // TODO: Add checks for ratePlanId and mealTypeId once implemented
  const { roomTypeId, adults, ratePlanId } = roomConfiguration;
  return (
    !!roomTypeId &&
    isUUID(roomTypeId) &&
    !!adults &&
    !!ratePlanId &&
    isUUID(ratePlanId)
  );
};

export const isRoomConfigurationConfigurable =
  (roomConfiguration: RoomConfiguration) =>
  (state: BookingStoreState): boolean => {
    return state.roomConfigurations
      .slice()
      .sort((a, b) => a.roomIndex - b.roomIndex)
      .slice(0, roomConfiguration.roomIndex)
      .reduce(
        (isConfigurable, configuration) =>
          isConfigurable && isRoomConfigurationValid(configuration),
        true,
      );
  };

const validateAutoOccupancy = (
  autoOccupancy: AutoOccupancy,
  site: Site,
): boolean => {
  const childrenMinAge = site.children_min_age;
  const simpleFieldValidation = validateOccupancyFields(
    autoOccupancy.adults,
    autoOccupancy.children,
  );

  if (!simpleFieldValidation) {
    return false;
  }

  if (autoOccupancy.adults > maxAdultsAutoOccupancy) {
    console.warn(
      `invalid auto_occupancy - max adults is ${maxAdultsAutoOccupancy}`,
    );
    return false;
  }

  if (autoOccupancy.children.length > maxChildrenAutoOccupancy) {
    console.warn(
      `invalid auto_occupancy - max children is ${maxChildrenAutoOccupancy}`,
    );
    return false;
  }

  const hasInvalidChildrenAge = autoOccupancy.children.some(
    (childAge) => childAge < childrenMinAge || childAge > maxChildrenAge,
  );
  if (hasInvalidChildrenAge) {
    console.warn(
      `invalid auto_occupancy - child age has to be between ${childrenMinAge} and ${maxChildrenAge}`,
    );
    return false;
  }

  return true;
};

const validateOccupancies = (occupancies: Occupancy[], site: Site): boolean => {
  const maxGuestsPerRoom = site.max_guests_per_room;
  const childrenMinAge = site.children_min_age;
  return occupancies.every((occupancy, index) => {
    const simpleFieldValidation = validateOccupancyFields(
      occupancy.adults,
      occupancy.children,
      occupancy.roomTypeId,
      index,
    );
    if (!simpleFieldValidation) {
      return false;
    }

    if (occupancy.adults + occupancy.children.length > maxGuestsPerRoom) {
      console.warn(
        `invalid occupancy for index ${index} - max guests per room is ${maxGuestsPerRoom}`,
      );
      return false;
    }

    const hasInvalidChildrenAge = occupancy.children.some(
      (childAge) => childAge < childrenMinAge || childAge > maxChildrenAge,
    );
    if (hasInvalidChildrenAge) {
      console.warn(
        `invalid occupancy for index ${index} - child age has to be between ${childrenMinAge} and ${maxChildrenAge}`,
      );
      return false;
    }

    return true;
  });
};

const validateStay = (
  arrival: Date | null,
  departure: Date | null,
): boolean => {
  if (!arrival || !departure) {
    return false;
  }

  if (!validateStayFields(arrival, departure)) {
    return false;
  }

  // TODO: check arrival and departure data with data from server -> availability api

  return true;
};

interface BookingStoreStepValidationResult {
  step: BookingStep;
  valid: boolean;
}

interface BookingStoreValidationResult {
  steps: BookingStoreStepValidationResult[];
}

export const validate = (
  state: BookingStoreState,
  site: Site,
): BookingStoreValidationResult => {
  // TODO: add other steps once implemented
  const orderedSteps = [
    BookingStep.Start,
    BookingStep.Occupancy,
    BookingStep.Calendar,
    BookingStep.AutoOccupancy,
    BookingStep.RoomConfigurations,
    BookingStep.Rooms,
  ];
  const steps: BookingStoreStepValidationResult[] = [];
  orderedSteps.forEach((step) => {
    switch (step) {
      case BookingStep.Start:
        steps.push({
          step,
          valid: !state.hydratedWithDefaultOccupancy,
        });
        break;

      case BookingStep.Occupancy:
        steps.push({
          step,
          valid: state.autoOccupancy
            ? validateAutoOccupancy(state.autoOccupancy, site)
            : validateOccupancies(state.occupancies, site),
        });
        break;

      case BookingStep.Calendar:
        steps.push({
          step,
          valid: validateStay(state.arrival, state.departure),
        });
        break;

      case BookingStep.AutoOccupancy:
        if (!state.autoOccupancy) {
          break;
        }
        steps.push({
          step,
          valid: false, // TODO: implement validation logic for auto occupancy step
        });
        break;

      case BookingStep.RoomConfigurations:
        steps.push({
          step,
          valid: false, // TODO: implement validation logic for rooms step
        });
        break;

      default:
        steps.push({
          step,
          valid: false,
        });
        break;
    }
  });
  return {
    steps,
  };
};

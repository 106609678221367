import classNames from "classnames";
import { useState } from "react";
import { BookingStep } from "../../@types";
import {
  selectNights,
  useBookingStore,
  useBookingStoreSetStep,
} from "../../hooks/useBookingStore";
import { AutoOfferRoom, AutoOffer as AutoOfferType } from "../../http/offerApi";
import { useRoomTypes } from "../../http/roomApi";
import { translate } from "../../i18n";
import Button from "../../ui/Button";
import { Card } from "../../ui/Card";
import Headline from "../../ui/Headline";
import { Label } from "../../ui/Label";
import Prices from "../../ui/Prices";
import Plus from "../../ui/icon/plus.svg?react";
import { groupAndAggregate } from "../../utils/list";
import { formatMoney } from "../../utils/number";
import { site } from "../../utils/site";
import RoomDetailModal from "../room/RoomDetailModal";
import styles from "./AutoOffer.module.css";

interface AutoOfferProps {
  index: number;
  autoOffer: AutoOfferType;
  className?: string;
}

interface GroupedOfferRoom {
  count: number;
  roomTypeId: string;
  name: string;
  price: number;
  currency: string;
  adults: number;
  children: number;
  occupancyCount: number;
}

const AutoOffer = ({ index, autoOffer, className }: AutoOfferProps) => {
  const setStep = useBookingStoreSetStep();
  const nights = useBookingStore(selectNights);
  const language = useBookingStore((state) => state.language);
  const setRoomConfigurations = useBookingStore(
    (state) => state.setRoomConfigurations,
  );
  const i18n = translate(language);
  const { roomTypes } = useRoomTypes();
  const [roomDetail, setRoomDetail] = useState<GroupedOfferRoom | null>();
  const visualIndex = index + 1;
  const [showAllRooms, setShowAllRooms] = useState(false);

  const offerOccupancyCounts = autoOffer.rooms.reduce(
    (sum, room) => {
      sum.adults += room.occupancy.adults;
      sum.children += room.occupancy.children?.length ?? 0;
      return sum;
    },
    {
      adults: 0,
      children: 0,
    },
  );

  const groupedRooms = groupAndAggregate(
    autoOffer.rooms,
    (room: AutoOfferRoom) =>
      `${room.room_type_id}_${room.occupancy.adults}_${
        room.occupancy.children?.length ?? 0
      }_${room.price.currency}`,
    (aggregated: GroupedOfferRoom | null, room) => {
      if (!aggregated) {
        const adults = room.occupancy.adults;
        const children = room.occupancy.children?.length ?? 0;
        return {
          count: 1,
          roomTypeId: room.room_type_id,
          name: room.name,
          price: room.price.amount,
          currency: room.price.currency,
          adults,
          children,
          occupancyCount: adults + children,
        };
      }

      aggregated.count++;
      aggregated.price += room.price.amount;

      return aggregated;
    },
  );

  const bookAutoOffer = () => {
    setRoomConfigurations(
      autoOffer.rooms.map((room, index) => ({
        roomIndex: index,
        roomTypeId: room.room_type_id,
        adults: room.occupancy.adults,
        children: room.occupancy.children ?? [],
      })),
    );
    setStep(BookingStep.RoomConfigurations);
  };

  return (
    <Card
      key={index}
      className={classNames(className)}
      footer={
        <div className={styles.cardFooter}>
          <Button
            buttonProps={{
              title: i18n.autoOccupancy.bookOption(visualIndex),
              onClick: bookAutoOffer,
            }}
          >
            {i18n.autoOccupancy.bookOption(visualIndex)}
          </Button>
        </div>
      }
    >
      <Label className={styles.topLabel}>
        <Headline
          as="h2"
          size={4}
          title={i18n.autoOccupancy.option(visualIndex)}
        />
      </Label>
      <div className={styles.priceContainer}>
        {i18n.autoOccupancy.from}
        <Headline
          size={1}
          title={formatMoney(autoOffer.prices.min.amount, language, {
            currency: autoOffer.prices.min.currency,
          })}
        />
        {autoOffer.prices.base.amount > autoOffer.prices.min.amount && (
          <div className={styles.basePrice}>
            {`${i18n.autoOccupancy.from} ${formatMoney(
              autoOffer.prices.base.amount,
              language,
              {
                currency: autoOffer.prices.base.currency,
              },
            )}`}
          </div>
        )}
      </div>
      {nights && <div>{i18n.autoOccupancy.totalPriceNights(nights)}</div>}
      <Label className={styles.occupancyLabel}>
        {i18n.autoOccupancy.occupancy(
          offerOccupancyCounts.adults,
          offerOccupancyCounts.children,
          autoOffer.rooms.length,
          site.room_term,
        )}
      </Label>
      {[...groupedRooms.values()]
        .slice(0, !showAllRooms ? 2 : groupedRooms.size)
        .map((room, index) => (
          <Room
            key={index}
            room={room}
            onOpenDetail={(room) => setRoomDetail(room)}
          />
        ))}
      {groupedRooms.size > 2 && !showAllRooms && (
        <Button
          layout="link"
          buttonProps={{
            title: i18n.autoOccupancy.showAllRooms(site.room_term),
            onClick: () => setShowAllRooms(true),
            className: styles.showAllRoomsButton,
          }}
        >
          <span className={styles.plusIconWrapper}>
            <Plus className={styles.plusIcon} />
          </span>
          {i18n.autoOccupancy.showAllRooms(site.room_term)}
        </Button>
      )}
      <RoomDetailModal
        room={
          roomTypes?.find((room) => room.id === roomDetail?.roomTypeId) ?? null
        }
        prices={
          roomDetail && (
            <Prices
              minPrice={{
                amount: roomDetail.price / roomDetail.count,
                currency: roomDetail.currency,
              }}
            />
          )
        }
        open={!!roomDetail}
        onClose={() => setRoomDetail(null)}
        footer={
          <>
            <Headline
              size={2}
              title={i18n.rooms.roomOption(visualIndex, site.room_term)}
            />
            <div className={styles.roomDetailFooter}>
              {[...groupedRooms.values()].map((room, index) => (
                <Room
                  key={index}
                  room={room}
                  onOpenDetail={(room) => setRoomDetail(room)}
                />
              ))}
              <Button
                buttonProps={{
                  title: i18n.autoOccupancy.bookOption(visualIndex),
                  onClick: bookAutoOffer,
                  className: styles.roomDetailFooterButton,
                }}
              >
                {i18n.autoOccupancy.bookOption(visualIndex)}
              </Button>
            </div>
          </>
        }
      />
    </Card>
  );
};

interface RoomProps {
  room: GroupedOfferRoom;
  onOpenDetail: (room: GroupedOfferRoom) => void;
}

const Room = ({ room, onOpenDetail }: RoomProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);

  return (
    <div className={styles.room}>
      <Headline size={5} title={`${room.count}x`} className={styles.count} />
      <div className={styles.content}>
        <Button
          layout="link"
          buttonProps={{
            title: room.name,
            onClick: () => onOpenDetail(room),
            className: styles.title,
          }}
        >
          {room.name}
        </Button>
        <Headline
          size={4}
          title={i18n.autoOccupancy.roomOccupancy(room.adults, room.children)}
        />
      </div>
      <Headline
        size={5}
        title={formatMoney(room.price, language, {
          currency: room.currency,
        })}
        className={styles.price}
      />
    </div>
  );
};

export default AutoOffer;

type GroupContainer<T> = Map<string, T[]>;

export const groupBy = <T>(
  items: T[],
  keyGetter: ((item: T) => string) | keyof T,
): GroupContainer<T> => {
  return items.reduce<GroupContainer<T>>(
    (groupsContainer: GroupContainer<T>, item: T) => {
      let groupKey: string;
      switch (typeof keyGetter) {
        case "function":
          groupKey = keyGetter(item);
          break;

        case "string": {
          const groupKeyValue = item[keyGetter];
          groupKey = `${groupKeyValue}`;
          break;
        }

        default:
          throw new Error(
            "`groupBy` - invalid argument for group key getter provided",
          );
      }
      let group = groupsContainer.get(groupKey);
      if (!group) {
        group = [];
        groupsContainer.set(groupKey, group);
      }

      group.push(item);
      return groupsContainer;
    },
    new Map<string, T[]>(),
  );
};

type TAggregated<T> = Map<string, T>;

export const groupAndAggregate = <T, TAggregatedItem>(
  items: T[],
  keyGetter: ((item: T) => string) | keyof T,
  aggregator: (aggregated: TAggregatedItem | null, item: T) => TAggregatedItem,
): TAggregated<TAggregatedItem> => {
  const groupsContainer = groupBy(items, keyGetter);
  const aggregated = new Map<string, TAggregatedItem>();
  groupsContainer.forEach((group, key) => {
    const aggregatedItem = group.reduce(
      (agg: TAggregatedItem | null, item: T) => aggregator(agg, item),
      null,
    );

    if (aggregatedItem) {
      aggregated.set(key, aggregatedItem);
    }
  });

  return aggregated;
};

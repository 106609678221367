import useSWR from "swr";
import { Photo } from "../@types";
import { useBookingStore } from "../hooks/useBookingStore";
import { fetcher } from "./utils";

export enum RatePlanType {
  Standard = "standard",
  Special = "special",
}

export enum BoardType {
  OvernightStayOnly = "overnight_stay_only",
  BedAndBreakfast = "bed_and_breakfast",
  HalfBoard = "half_board",
  ThreeQuarterBoard = "three_quarter_board",
  FullBoard = "full_board",
  AllInclusive = "all_inclusive",
}

export interface RatePlans {
  rate_plans: RatePlan[];
}

export interface RatePlan {
  id: string;
  type: RatePlanType;
  title: string;
  description: string;
  photos: Photo[];
  inclusives: {
    id: string;
    title: string;
    description: string;
    photo?: Photo;
  }[];
  reservation_conditions: string[];
  boards: {
    type: BoardType;
    description: string;
    photo?: Photo;
  }[];
}

// TODO: replace with live url: `/properties/${propertyId}/rate_plans`
const ratePlansURL = "/rate-plans-de.json";

export const useRatePlans = () => {
  const language = useBookingStore((state) => state.language);

  return useSWR({ url: ratePlansURL, language }, (opts) =>
    fetcher<RatePlans>(opts).then((res) => res.rate_plans),
  );
};

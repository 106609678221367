import { BookingStep } from "../../@types";
import {
  useBookingStore,
  useBookingStoreSetStep,
} from "../../hooks/useBookingStore";
import useMediaQuery from "../../hooks/useMediaQuery";
import PageWithHeader from "../../pages/PageWithHeader";
import Form from "../../ui/Form";
import MobileModal from "../../ui/MobileModal";
import styles from "./Calendar.module.css";
import CalendarForm from "./CalendarForm";
import { FormDataCalendar } from "./utils";

const Calendar = () => {
  const setStep = useBookingStoreSetStep();
  const setArrivalAndDeparture = useBookingStore(
    (state) => state.setArrivalAndDeparture,
  );
  const arrival = useBookingStore((state) => state.arrival);
  const departure = useBookingStore((state) => state.departure);
  const autoOccupancy = useBookingStore((state) => state.autoOccupancy);
  const isDesktop = useMediaQuery("(min-width: 1126px)");

  return (
    <PageWithHeader backgroundImage={true}>
      <MobileModal
        isOpen={true}
        align="right"
        size="large"
        onClose={() => setStep(BookingStep.Start)}
      >
        <Form<FormDataCalendar>
          defaultValues={{
            arrival: arrival,
            departure: departure,
          }}
          className={styles.form}
          onSubmit={(data) => {
            setArrivalAndDeparture({
              arrival: data.arrival,
              departure: data.departure,
            });
            setStep(
              autoOccupancy
                ? BookingStep.AutoOccupancy
                : BookingStep.RoomConfigurations,
            );
          }}
        >
          <CalendarForm
            isDesktop={isDesktop}
            key={isDesktop ? "desktop" : "mobile"}
          />
        </Form>
      </MobileModal>
    </PageWithHeader>
  );
};

export default Calendar;
